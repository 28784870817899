import { colors } from '@marvelapp/theme';
import { hexToRGB } from '../utils';
var white25 = hexToRGB(colors.white, 0.25);
export var inputKinds = {
    default: {
        bg: 'transparent',
        color: 'slate',
        boxShadow: 'input.default',
        hoverBoxShadow: 'input.defaultHover',
        focusBoxShadow: 'input.defaultFocus',
        placeholderColor: 'silver',
        placeholderOpacity: 1.0,
    },
    error: {
        bg: 'transparent',
        color: 'red',
        boxShadow: 'input.error',
        hoverBoxShadow: 'input.error',
        focusBoxShadow: 'input.error',
        placeholderColor: 'silver',
        placeholderOpacity: 1.0,
    },
    disabled: {
        bg: 'snow',
        color: 'silver',
        boxShadow: 'input.disabled',
        hoverBoxShadow: 'input.disabled',
        focusBoxShadow: 'input.disabled',
        placeholderColor: 'silver',
        placeholderOpacity: 1.0,
    },
    whiteTransparent: {
        bg: white25,
        color: 'white',
        hoverBoxShadow: 'input.whiteTransparentHover',
        focusBoxShadow: 'input.whiteTransparentFocus',
        placeholderColor: 'white',
        placeholderOpacity: 0.8,
    },
    underline: {
        bg: 'transparent',
        color: 'slate',
        boxShadow: 'input.underline',
        hoverBoxShadow: 'input.underlineHover',
        focusBoxShadow: 'input.underlineFocus',
        borderRadius: '0px',
        placeholderColor: 'silver',
    },
    transparent: {
        bg: 'transparent',
        color: 'slate',
        placeholderColor: 'silver',
    },
    design: {
        bg: 'transparent',
        color: 'slate',
        boxShadow: 'none',
        hoverBoxShadow: 'input.default',
        focusBoxShadow: 'input.defaultFocus',
        placeholderColor: 'silver',
    },
};
