var kinds = {
    normal: {
        color: 'slate',
        hoverColor: 'licorice',
    },
    danger: {
        color: 'slate',
        hoverColor: 'red',
    },
    black: {
        color: 'black',
        hoverColor: 'licorice',
    },
    slateMarvel: {
        color: 'slate',
        hoverColor: 'marvelDark',
    },
    licorice: {
        color: 'licorice',
        hoverColor: 'slate',
    },
    white: {
        color: 'white',
        hoverColor: 'snowDark',
    },
    silver: {
        color: 'silver',
        hoverColor: 'slate',
    },
    marvel: {
        color: 'marvelDark',
        hoverColor: 'marvel',
    },
    red: {
        color: 'red',
        hoverColor: 'redDark',
    },
    yellow: {
        color: 'yellowDark',
        hoverColor: 'yellowDark',
        hoverOpacity: 0.8,
    },
    notificationBar: {
        color: 'white',
        hoverColor: 'snowDark',
        fontWeight: 500,
        fontSize: 2,
    },
};
export default kinds;
