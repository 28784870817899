import { useEffect, useLayoutEffect } from 'react';
var canUseDOM = !!(typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement);
export function useLockBodyScroll() {
    useLayoutEffect(function () {
        if (!canUseDOM)
            return;
        // Prevent scrolling on mount
        document.body.style.overflow = 'hidden';
        // Re-enable scrolling when component unmounts
        return function () {
            document.body.style.overflow = 'visible';
        };
    }, []); // Empty array ensures effect is only run on mount and unmount
}
export function useKey(handler, _a) {
    var key = _a.key, _b = _a.keyEvent, keyEvent = _b === void 0 ? 'keydown' : _b;
    useEffect(function () {
        if (!canUseDOM)
            return;
        if (handler) {
            var handleEvent_1 = function (event) {
                if (event.key === key) {
                    handler(event);
                }
            };
            document.addEventListener(keyEvent, handleEvent_1);
            return function () {
                document.removeEventListener(keyEvent, handleEvent_1);
            };
        }
    }, [handler, key, keyEvent]);
}
export function useOnClickOutside(ref, handler) {
    useEffect(function () {
        if (!canUseDOM)
            return;
        var listener = function (event) {
            // Do nothing if clicking ref's element or descendent elements
            if (!handler || !ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return function () {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
}
