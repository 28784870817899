import React from 'react';
import Box from './Box';
import Flex from './Flex';
import { PulseAnimation } from './PulsatingButton';
export default function UnreadBubble(_a) {
    var children = _a.children, marginLeft = _a.marginLeft, _b = _a.shouldPulse, shouldPulse = _b === void 0 ? true : _b;
    return (React.createElement(Pulse, { marginLeft: marginLeft, shouldPulse: shouldPulse },
        React.createElement(Flex, { alignItems: "center", bg: "red", borderRadius: children ? 10 : 4, boxShadow: "button.danger", color: "white", "data-testid": "comment-count-bubble", fontSize: "9px", fontWeight: 600, height: children ? 18 : 8, hoverBoxShadow: "button.dangerHover", justifyContent: "center", minWidth: children ? 18 : 8, pt: "1px", px: 1, width: children ? 'auto' : 8 }, children)));
}
function Pulse(_a) {
    var marginLeft = _a.marginLeft, shouldPulse = _a.shouldPulse, children = _a.children;
    if (shouldPulse) {
        return (React.createElement(PulseAnimation, { color: "red", isSmall: true, marginLeft: marginLeft }, children));
    }
    return React.createElement(Box, { marginLeft: marginLeft }, children);
}
